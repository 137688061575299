import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [4,2,3];

export const dictionary = {
		"/": [~5],
		"/login": [13],
		"/logout": [~14],
		"/payment/success": [~15],
		"/privacy": [16],
		"/terms": [17],
		"/tutor/edit": [18,[4]],
		"/tutor/edit/plans": [19,[4]],
		"/tutor/edit/trial": [~20,[4]],
		"/tutor/edit/workdays": [21,[4]],
		"/tutor/invite": [22,[4]],
		"/tutor/me": [~23,[4]],
		"/tutor/me/payments": [~24,[4]],
		"/tutor/squad/[squad]": [~25,[4]],
		"/tutor/username": [26,[4]],
		"/[tutor]": [6,[2]],
		"/[tutor]/invite": [12,[2]],
		"/[tutor]/[plan]": [~7,[2,3]],
		"/[tutor]/[plan]/book": [~8,[2,3]],
		"/[tutor]/[plan]/class/[cls]": [~9,[2,3]],
		"/[tutor]/[plan]/class/[cls]/complete": [10,[2,3]],
		"/[tutor]/[plan]/order": [~11,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';